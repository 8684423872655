<template>
  <div class="iframe-box">
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.url = this.$route.params.url;
    console.log(this.url,'webViewUrl');
  },
};
</script>

<style lang="scss" scoped>
.iframe-box {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>